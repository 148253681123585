/**
 * Layout class
 * Mettre ici les trucs généraux qui concernent le layout.
 *
 * @author Stef Funaro
 *
 */
import { ThrottleHelper } from "../helpers/ThrottleHelper";
import { System } from "../core/System";
// import { MatchHeight } from "js-match-height";

export class Layout extends EventTarget {
	// Surveiller les changements de breakpoints. Mettre à false si vous n'utilisez pas, pour une meilleure performance.
	_checkBreakpointChangeOnResize = true;
	//Pour afficher une petite boite indiquant le breakpoint dans le coin de la page.
	_showBPDebug = false;
	_lastBreakpoint = null;
	_currentBreakpoint = null;
	ON_DOC_RESIZE_EVENT = "layout-resize-event";
	ON_BREAKPOINT_CHANGE_EVENT = "layout-breakpoint-change-event";

	constructor(app) {
		super();
		this.app = app;
		console.log("Henri Layout constructor");
	}

	init() {
		console.log("Henri Layout init!");

		this._setBPDebug();
		this._initBreakpoints();

		// Resize event (slowed down)
		window.addEventListener(
			"resize",
			ThrottleHelper.throttle(this._handleDocResize, 1000, this)
		);
		window.dispatchEvent(new Event("resize"));

		// on orientation change event
		window.addEventListener(
			"orientationchange",
			this._handleDocResize.bind(this)
		);

		this.doMatchHeight(this.app.mainContainer);
		this._doSpecialTricks();

		return this;
	}

	doMatchHeight(container = null, item = ".matchHeight") {
		if (container === null) {
			container = this.app.mainContainer;
		}
		//Match height
		this._matchHeight(container, item);
	}

	/**
	 * @returns {Number}
	 */
	get winW() {
		return this._winW;
	}

	/**
	 * @returns {Number}
	 */
	get winH() {
		return this._winH;
	}

	/**
	 * @returns {Number}
	 */
	get viewportW() {
		return this._viewportW;
	}

	/**
	 * @returns {Number}
	 */
	get viewportH() {
		return this._viewportH;
	}

	/**
	 * Is the viewport smaller than breakpoint ?
	 * @param breakpoint {string}
	 * @returns {boolean}
	 */
	isUnderBreakpoint(breakpoint) {
		return this._viewportW < this[`bp_${breakpoint}`];
	}

	/**
	 * is Breakpoint bigger or equal than breakpoint ?
	 * @param breakpoint {string}
	 * @returns {boolean}
	 */
	isOverBreakpoint(breakpoint) {
		return this._viewportW >= this[`bp_${breakpoint}`];
	}

	/***********************************************************
	 * PRIVATES
	 ************************************************************/

	/**
	 * Affichage d'une boite indiquant sur quel breakpoint on est.
	 * @private
	 */
	_setBPDebug() {
		if (this._showBPDebug) {
			if (!this._checkBreakpointChangeOnResize) {
				console.error(
					"Impossible d'afficher le debug, la variable _checkBreakpointChangeOnResize est à false"
				);
				return;
			}
			this._debugEl = document.createElement("div");
			this._debugEl.setAttribute("id", "henri-bp-debug");
			this._debugEl.innerText = "Debug";
			document.body.append(this._debugEl);
			console.log("Layout._setBPDebug!");
		}
	}

	/**
	 * @private
	 */
	_doSpecialTricks() {
		// Automatic centering Vertical - Only once on load
		document.querySelectorAll(".js-center-v-once").forEach((element, index) => {
			this._centerV(element);
		});

		// Automatic centering Horizontal - Only once on load
		document.querySelectorAll(".js-center-h-once").forEach((element, index) => {
			this._centerH(element);
		});
	}



	/**
	 * Centrage vertical dans son parent (dernier recours, essayez fort en css avant!)
	 * @private
	 */
	_centerV(element) {
		if (element.style.position === "static") {
			element.style.position = "relative";
		}
		// TODO: convert jquery center

		// let t = Math.floor($(this).parent().height() / 2) - $(this).height() / 2;
		// if (t < 0) {
		// 	t = 0;
		// }
		// element.style.top = t + "px";
	}

	/**
	 * Centrage horizontal dans son parent (dernier recours, essayez fort en css avant!)
	 * @private
	 */
	_centerH(element) {
		if (element.style.position === "static") {
			element.style.position = "relative";
		}
		// TODO: convert jquery center

		// let l = Math.floor($(this).parent().width() / 2) - $(this).width() / 2;
		// if (l < 0) {
		// 	l = 0;
		// }
		// element.style.left = l + "px";
	}

	/**
	 * Resize
	 */
	_handleDocResize() {
		this._winW = window.innerWidth;

		this._winH = window.innerHeight;

		this._viewportW = Math.max(
			document.documentElement.clientWidth,
			window.innerWidth || 0
		);
		this._viewportH = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight || 0
		);

		// Check breakpoint change
		if (this._checkBreakpointChangeOnResize) {
			this._lastBreakpoint = this._currentBreakpoint;
			// for (let i = 0; i < this._breakpointsNamesArray.length; i++) {
			for (let i = this._breakpointsNamesArray.length - 1; i >= 0; i--) {
				// console.log("_handleDocResize check bp", this._viewportW, this._breakpointsNamesArray[i], this[`bp_${this._breakpointsNamesArray[i]}`]);
				if (this._viewportW < this[`bp_${this._breakpointsNamesArray[i]}`]) {
					this._currentBreakpoint = this._breakpointsNamesArray[i];
				} else {
					break;
				}
			}
			if (this._currentBreakpoint !== this._lastBreakpoint) {
				this._onBreakpointChange();
			}
			this.doMatchHeight(this.app.mainContainer);
		}

		// Automatic centering Vertical
		document.querySelectorAll(".js-center-v").forEach((element, index) => {
			this._centerV(element);
		});

		// Automatic centering Horizontal
		document.querySelectorAll(".js-center-h").forEach((element, index) => {
			this._centerH(element);
		});

		const event = new CustomEvent(this.ON_DOC_RESIZE_EVENT, {
			detail: {
				w: this._winW,
				h: this._winH,
				breakpoint: this._currentBreakpoint
			}
		});
		console.log("Layout._handleDocResize", event);
		this.dispatchEvent(event);
		// this.dispatchEvent(this.ON_DOC_RESIZE_EVENT, this._winW, this._winH);

		return true;
	}

	/**
	 * Stockage de breakpoints du site
	 * Pris des variables dans le root du css (déclarées dans constants.scss)
	 * @private
	 */
	_initBreakpoints() {
		// Breakpoints list
		const breakpointsFromCss = System.getCssVariable("breakpoints-names");
		this._breakpointsNamesArray = breakpointsFromCss.split(",");
		console.log("Layout._breakpointsNamesArray=", this._breakpointsNamesArray);

		let bp;
		for (let i = 0; i < this._breakpointsNamesArray.length; i++) {
			bp = parseInt(
				System.getCssVariable(`breakpoint-${this._breakpointsNamesArray[i]}`),
				10
			);
			this["bp_" + this._breakpointsNamesArray[i]] = isNaN(bp) ? 0 : bp;
			console.log(
				"Layout.breakpoint",
				this._breakpointsNamesArray[i],
				this[`bp_${this._breakpointsNamesArray[i]}`]
			);
		}
		return true;
	}

	/**
	 * Changement de breakpoint
	 * @private
	 */
	_onBreakpointChange() {
		if (this._currentBreakpoint !== null) {
			const event = new CustomEvent(this.ON_BREAKPOINT_CHANGE_EVENT, {
				detail: {
					w: this._winW,
					h: this._winH,
					breakpoint: this._currentBreakpoint,
					lastBreakpoint: this._lastBreakpoint
				}
			});
			this.dispatchEvent(event);
			// this._debugEl.innerText = this._currentBreakpoint+":"+this._winW;
			if (this._debugEl !== null) {
				this._debugEl.innerText = this._currentBreakpoint;
			}
			console.log(
				"%c Breakpoint change!:",
				"background: #222; color: turquoise",
				this._currentBreakpoint,
				event
			);
		}
		return true;
	}

	_matchHeight(container, item) {
		if(container === null || item === null){
			return;
		}
		let currentTallest = 0,
			currentRowStart = 0,
			topPosition = 0,
			$el;
		const rowDivs = [];
		Array.from(container.querySelectorAll(item)).forEach((el, i) => {
			el.style.height = "auto";
			topPosition = el.offsetTop;
			console.log(
				"matchheight",
				el,
				topPosition,
				currentRowStart,
				currentTallest,
				rowDivs
			);
			if (currentRowStart != topPosition) {
				for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					rowDivs[currentDiv].style.height = currentTallest + "px";
				}
				rowDivs.length = 0;
				currentRowStart = topPosition;
				currentTallest = parseFloat(
					getComputedStyle(el, null).height.replace("px", "")
				);
				rowDivs.push(el);
			} else {
				rowDivs.push(el);
				currentTallest =
					currentTallest <
					parseFloat(getComputedStyle(el, null).height.replace("px", ""))
						? parseFloat(getComputedStyle(el, null).height.replace("px", ""))
						: currentTallest;
			}
			for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
				rowDivs[currentDiv].style.height = currentTallest + "px";
			}
		});
	}
}

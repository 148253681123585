/**
 * Navigation class
 * Mettre ici les trucs généraux qui concernent la navigation.
 *
 * @author Stef Funaro
 *
 */
import { System } from "../core/System";
import { ThrottleHelper } from "../helpers/ThrottleHelper";
import gsap from "gsap";

export class Navigation {
	//
	_isOkToInit = false;
	//
	_scrollToOffset = 0;
	_lastScroll = 0;
	//
	// Sticky
	_stickyHeader = false;
	// À quelle position de scroll le style de la nav change
	_stickyHeaderStyleSwitchedPosition = 50;
	// À quelle position de scroll la nav sticky se cache
	_stickyHeaderShowHidePosition = 400;

	/**
	 * Constructor
	 * @param app
	 */
	constructor(app) {
		this.app = app;
		console.log("Henri Navigation constructor");
		// Header tag
		if(this.app.mainContainer === null){
			console.error("Navigation missing mainContainer");
			return;
		}
		this._headerEl = this.app.mainContainer.querySelector("header");
		if (this._headerEl === null) {
			//Dans l'admin de WP, le JS va stopper ici, on a pas de header!
			console.error("Navigation missing <header>");
			return;
		}
		// Main nav div
		this._mainNav = this._headerEl.querySelector("#navbar-main");
		if (this._mainNav === null) {
			console.error("Navigation missing div: #navbar-main");
			return;
		}
		// Sticky nav
		const stickyHeaderEl = this._headerEl.querySelector(".sticky-header");
		if (stickyHeaderEl !== null) {
			this._stickyHeader = true;
			this._stickyHeaderEl = stickyHeaderEl;
		}

		// Size ou la nav se collapse
		this._navCollapseBreakpointSize = parseInt(
			System.getCssVariable("navbar-collapse-size"),
			10
		);

		this._isOkToInit = true;

		//Enlever les titles en rollover (voir note sur fonction)
		//this._removeTitles();
	}

	/******************************************
	 * Public
	 * ******************************************/

	/**
	 * Init
	 */
	init() {

		if(!this._isOkToInit) return;

		console.log("Henri Navigation init!");
		this._setAnchorLinks();

		// Resize event (slowed down)
		document.addEventListener(
			"scroll",
			ThrottleHelper.throttle(this._handleScroll, 100, this)
		);
		document.dispatchEvent(new Event("scroll"));

		// S'il y a un hash, on y scroll en anim!
		if (System.urlHasHash) {
			const hash = System.urlHash;
			if (typeof hash === "string") {
				this.scrollToElemId(hash);
			}
		}

		// Burger
		this._setBurgerNav();

		return this;
	}

	/**
	 * Scroll to element with #ID
	 * @param id
	 */
	scrollToElemId(id) {
		// On enleve le # pour etre sur de partir de la meme chose, qu'il y ait un # ou non
		const cleanedId = id.replace("#", "");
		const elem = document.querySelector(`#${cleanedId}`);
		if (elem !== null) {
			this.scrollToElem(elem);
		}
	}

	/**
	 * Scroll to HTML element
	 * @param elem
	 */
	scrollToElem(elem) {
		console.log("scrollToElem", elem);
		const rect = elem.getBoundingClientRect();
		const targetOffset = rect.top + window.scrollY - this._scrollToOffset;
		this.scrollToPos(targetOffset);
	}

	/**
	 * Scroll to position (in pixel
	 * @param pos
	 */
	scrollToPos(pixelsPos) {
		// gsap.to(window, this._scrollToSpeed, { scrollTo: pos });
		window.scrollTo({
			top: pixelsPos,
			behavior: "smooth"
		});
		// console.log("scrollToPos", pos);
	}

	/**
	 * Enlever les title sur tous les liens, en roll-over et les remettre après. Ça masque les petits tooltips.
	 * Activer seulement pour projets pour l'accessibilité est moins importante et le look importe.
	 */
	_removeTitles() {
		document.querySelectorAll("a").forEach((linkEl, index) => {
			const title = linkEl.getAttribute("title");
			if (title !== null) {
				linkEl.addEventListener("mouseover", e => {
					linkEl.setAttribute("title", "");
				});
				linkEl.addEventListener("mouseout", e => {
					linkEl.setAttribute("title", title);
				});
			}
		});
	}

	/******************************************
	 * BURGER NAV
	 * Pour les burger menu qui s'ouvrent de haut en bas.
	 * Utillise collapse de boostrap, mais avec les CSS notre code fait en sorte que le menu s'ouvre pleine hauteur, désactive le scroll de la page et le scroll ne scrolle que le menu.
	 * TODO: Documenter option pour la nav qui ouvre de côté
	 * ******************************************/

	/**
	 * Burger Nav
	 * @private
	 */
	_setBurgerNav() {
		// Burger btn exists ?
		const burgerBtn = this._headerEl.querySelector("button[data-toggle='collapse-henri']");
		if (burgerBtn === null) {
			return;
		}
		// Yes, keep going!
		this._burgerBtn = burgerBtn;
		// Collapsed nav exists ?
		const collapseNavElId = this._burgerBtn.dataset.target;
		const collapseNavEl = this._headerEl.querySelector(collapseNavElId);
		if (collapseNavEl === null) {
			return;
		}
		// Yes, keep going!
		// GO!
		this._mainNavIsCollapsable = true;
		this._collapseNavEl = collapseNavEl;
		// Button click
		this._burgerBtn.addEventListener("click", e => {
			e.preventDefault();
			const target = e.currentTarget;
			if (target.classList.contains("collapsed")) {
				// Is closed !
				target.classList.remove("collapsed");
				this.openBurgerNav();
			} else {
				// Is opened ?
				target.classList.add("collapsed");
				this.closeBurgerNav();
			}
		});
	}

	/**
	 * Ouverture du menu burger
	 */
	openBurgerNav() {
		document.body.classList.add("opened-nav");
		this.app.mainContainer.classList.add("opened-nav");
		gsap.fromTo(
			this._collapseNavEl,
			0.4,
			{ opacity: 0 },
			{
				opacity: 1,
				display: "block"
			}
		);
	}

	/**
	 * Fermerture du menu burger
	 */
	closeBurgerNav() {
		document.body.classList.remove("opened-nav");
		this.app.mainContainer.classList.remove("opened-nav");
		gsap.to(this._collapseNavEl, 0.4, {
			opacity: 0,
			display: "none",
			onComplete: e => {
				// Enlever l'opacity, parce qu'en resize, le display change par css avec !important et on veut pas de l'opacité dans les pattes
				this._collapseNavEl.style.opacity = "1";
			}
		});
	}

	/******************************************
	 * Privates
	 * ******************************************/

	/**
	 * on scroll event
	 * @private
	 */
	_handleScroll(event) {
		const scrollTop = window.scrollY;
		console.log("_handleScroll", scrollTop, this._lastScroll);
		// console.log("_onScroll scrollTop=", scrollTop);
		if (this._stickyHeader) {
			// Petit truc pour faire disparaitre nav en scroll et réaparaitre en scroll up
			// console.log("_onScroll this.stickyHeaderBuffSize=", this.stickyHeaderBuffSize);
			if (scrollTop < this._stickyHeaderShowHidePosition) {
				document.body.classList.remove("nav-scrolled-hide");
				this.app.mainContainer.classList.remove("nav-scrolled-hide");
			} else {
				if (this._lastScroll <= scrollTop) {
					document.body.classList.add("nav-scrolled-hide");
					this.app.mainContainer.classList.add("nav-scrolled-hide");
				} else {
					document.body.classList.remove("nav-scrolled-hide");
					this.app.mainContainer.classList.remove("nav-scrolled-hide");
				}
			}
			this._lastScroll = scrollTop;
			// Petit truc qui change le look de la nav quand on est un peu scrollé dans la page
			if (scrollTop > this._stickyHeaderStyleSwitchedPosition) {
				document.body.classList.add("nav-scrolled");
				this.app.mainContainer.classList.add("nav-scrolled");
			} else {
				document.body.classList.remove("nav-scrolled");
				this.app.mainContainer.classList.remove("nav-scrolled");
			}
		}
	}

	/**
	 * Lien avec ancres, animation
	 * @private
	 */
	_setAnchorLinks() {
		const anchorLinks = this.app.mainContainer.querySelectorAll(
			"a[href^='#']:not([href='#'])"
		);
		anchorLinks.forEach((link, index) => {
			// Exceptions
			if (link.hasAttribute("data-vc-accordion")) return;
			if (link.hasAttribute("data-vc-tabs")) return;
			if (link.hasAttribute("data-toggle")) return;
			// Click
			link.addEventListener("click", e => {
				const target = e.currentTarget;
				if (!target) return;
				e.preventDefault();
				const sectionID = target.getAttribute("href");
				if (sectionID !== null) {
					const elem = document.querySelector(sectionID);
					if (elem !== null) {
						return this.scrollToElem(elem);
					}
				}
			});
		});
	}
}

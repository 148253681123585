/**
 * Forms class
 * Mettre ici les trucs généraux qui concernent les formulaires.
 *
 * @author Stef Funaro
 *
 */

export class Forms {
	constructor (app) {
		console.log("Henri Forms constructor");
	}

	init () {
		console.log("Henri Forms init!");
		return this;
	}
}

/**
 * Marquee animation
 *
 */
export class Marquee {

	static go(el, speed) {
		const parentSelector = el;
		const clone = parentSelector.innerHTML;
		const firstElement = parentSelector.children[0];
		let i = 0;
		console.log(firstElement);
		parentSelector.insertAdjacentHTML("beforeend", clone);
		parentSelector.insertAdjacentHTML("beforeend", clone);
		parentSelector.insertAdjacentHTML("beforeend", clone);
		parentSelector.insertAdjacentHTML("beforeend", clone);
		parentSelector.insertAdjacentHTML("beforeend", clone);
		parentSelector.insertAdjacentHTML("beforeend", clone);

		setInterval(function () {
			firstElement.style.marginLeft = `-${i}px`;
			if (i > firstElement.clientWidth) {
				i = 0;
			}
			i = i + speed;
		}, 0);
	}


}

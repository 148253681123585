/**
 * IMPORT LIBRARIES
 */
import "bootstrap";
// import "swiper/bundle";

/**
 * IMPORT APP
 */
import { App } from "./henri/App";

/**
 * APP INIT
 */

// Instance app
const app = new App();

// On doc ready
document.addEventListener("DOMContentLoaded", function (event) {
	// Petite sécurité qui nous a réglé plusieurs problèmes random, doc ready + timeout de zero avant de starter.
	setTimeout(function () {
		if (globalThis.henriAppInited === true) {
			console.error("HENRI APP ALREADY INITED!! Included twice ? ");
			return;
		}
		globalThis.henri = app;
		app.init();
		globalThis.henriAppInited = true;
	}, 100);
});

/**
 *	ATTENTION
 *	NE PAS METTRE DE CODE ICI, UTILISER DES CLASSES ET LES IMPORTER!
 *
 */
/**
 * Animation class
 * Mettre ici les trucs généraux qui concernent les animations.
 *
 * @author Stef Funaro
 *
 */
// import { ThrottleHelper } from "../helpers/ThrottleHelper";
import * as AOS from "aos";
// import * as Rellax from "rellax";
import simpleParallax from "simple-parallax-js";
import { Marquee } from "../animations/Marquee";

export class Animation {
	constructor (app) {
		console.log("Henri Animation constructor");

		this._doCustomAnimations();
	}

	init () {
		console.log("Henri Animation init!");

		this._doCustomAnimations();

		// this._setRellax();
		this._setAOS();
		// this._setVivus();
		this._setMarquee();
		this._setParallax();

		// On Scroll
		// document.addEventListener("scroll", ThrottleHelper.throttle(this._handleScroll, 100, this));

		return this;
	}

	/***************************************************
	 * Privates
	 **************************************************/

	/**
	 * Custom animations
	 */
	_doCustomAnimations () {

		// gsap.to(".module-hero", 2, {opacity:1});

	}

	/**
	 * _handleScroll animations
	 * @private
	 */
	_handleScroll () {

	}

	/**
	 * Set Rellax
	 * Animation parallax en scroll (éléments flottants)
	 * https://dixonandmoe.com/rellax/
	 *
	 * Attention:
	 * Faire npm install rellax --save
	 * Après: import dans ce fichier en haut
	 *
	 */
	_setRellax () {
		// const rellax = new Rellax(".rellax", {
		// 	speed: -2,
		// 	center: false,
		// 	wrapper: null,
		// 	round: true,
		// 	vertical: true,
		// 	horizontal: false
		// });
	}

	/**
	 * On utilise simple-parallax
	 * @doc https://github.com/geosigno/simpleParallax.js/
	 * @private
	 */
	_setParallax(){
		var images = document.querySelectorAll(".parallax-img");
		new simpleParallax(images,{scale:1.5});
	}

	/**
	 * _setVivus
	 * @private
	 *
	 * Animation de svg. Effect cool de svg qui se dessine, ne fonctionne que sur les "strokes"
	 * ATTENTION: Il faut installer la librairie: npm install vivus
	 * Et l'importer dans ce fichier
	 *
	 * https://maxwellito.github.io/vivus/
	 * Preview svg: https://maxwellito.github.io/vivus-instant/
	 *
	 */
	_setVivus () {
		// Loop et ajout automatique d'ID pour animer les svg
		// let vivCnt = 0;
		// $(".animated-svg").each(function () {
		// 	$(this).attr("id", "viv-" + vivCnt);
		// 	new Vivus("viv-" + vivCnt, { duration: 120, delay: 100 });
		// 	vivCnt++;
		// });
	}

	/**
	 * _setMarquee
	 * @private
	 *
	 * Bande défilante
	 *
	 */
	_setMarquee () {
		document.querySelectorAll(".marquee").forEach((listEl, index) => {
			try {
				Marquee.go(listEl,0.2);
			}
			catch (e) { // <-- note `e` has explicit `unknown` type
				e.message; // errors
				console.log("Erreur Marquee=",e);
			}
		});
	}

	/**
	 * _setAOS
	 * @private
	 *
	 * Animation en scroll
	 *
	 */
	_setAOS () {
		// AOS
		AOS.init({
		// 	// Global settings:
		// 	// disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
		// 	// startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
		// 	// initClassName: 'aos-init', // class applied after initialization
		// 	// animatedClassName: 'aos-animate', // class applied on animation
		// 	// useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		// 	// disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		// 	// debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
		// 	// throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
		//
		// 	// // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		// 	// offset: 120, // offset (in px) from the original trigger point
		// 	// delay: 0, // values from 0 to 3000, with step 50ms
		// 	duration: 1800, // values from 0 to 3000, with step 50ms
		// 	// easing: 'ease', // default easing for AOS animations
		// 	// once: false, // whether animation should happen only once - while scrolling down
		// 	mirror: true // whether elements should animate out while scrolling past them
		// 	// anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
		//
		});
	}


}

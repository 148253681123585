/**
 * System class
 *
 * @author Stef Funaro
 *
 */
import configs from "./Configs";

export class System {
	static get isHttps() {
		return document.location.protocol === "https:";
	}

	static get isMobile() {
		const query = window.matchMedia(
			`only screen and (max-width: ${configs.mobileScreenSize}px)`
		);
		return query.matches;
	}

	/**
	 * get all css variables
	 */
	static get cssVariables() {
		if (this._cssVariables == null) {
			this._cssVariables = window.getComputedStyle(document.body);
		}
		return this._cssVariables;
	}

	/**
	 * get css variable by name
	 * @param name
	 */
	static getCssVariable(name) {
		return this.cssVariables.getPropertyValue(`--${name}`);
	}

	/**
	 * Get hash value in URL
	 * return string | boolean
	 */
	static get urlHash() {
		let value = false;
		const hash = location.hash;
		if (hash) {
			value = location.hash.substr(1);
		}
		return value;
	}

	/**
	 * Get has Hash Value in URL
	 * @return {bool}
	 */
	static get urlHasHash() {
		const hash = location.hash;
		return !!hash;
	}
}

/**
 * Swipers class
 * Ici on gère les carrousels swipers. Créez les configs dans l'objet "skins", vous ne devriez pas avoir à toucher le reste du code.
 *
 * Documentation:
 * https://swiperjs.com/get-started/
 * https://swiperjs.com/api/
 *
 * Les caroussels doivent être contenus dans un div "swiper-container" et avoir la classe "swiper".
 * Le parent doit avoir l'attribut data-skin s'il n'utilise pas le skin par défaut ("default").
 * Le parent est requis pour pouvoir extraire les flèches et les lier ensemble.
 * Le container se verra attribué une classe .theme-skin
 *
 * Les boutons next/previous doivent avoir les classes suivantes: swiper-button-next & swiper-button-prev
 *
 *
 * @author Stef Funaro
 *
 */

import Swiper from "swiper/swiper-bundle";
// import Swiper from "swiper";

export class Swipers {
	// Régler les différents styles ici (dupliquer default)
	skins = {
		/**
		 * Skin default
		 */
		default: {
			direction: "horizontal",
			loop: false,
			breakpoints: {
				0: {
					// slidesPerView: 1.3,
					// spaceBetween: 8,
					// slidesPerGroup:3
				},
				500: {
					// slidesPerView: 2.2,
					// spaceBetween: 8,
					// slidesPerGroup:2
				},
				768: {
					// slidesPerView: 2.4,
					// spaceBetween: 16,
					// slidesPerGroup:2
				},
				992: {
					// slidesPerView: 2.3,
					// spaceBetween: 32,
					// slidesPerGroup:2
				},
				1024: {
					// slidesPerView: 2.4,
					// spaceBetween: 32,
					// slidesPerGroup:2
				},
				1280: {
					// slidesPerView: 3.3,
					// spaceBetween: 32,
					// slidesPerGroup:3
				}
			},
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true
			},
			on: {
				init: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				},
				slideChange: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				}
			}
		},
		/**
		 * Skin hero
		 */
		hero: {
			direction: "horizontal",
			loop: true,
			slidesPerView: 1,
			slidesPerGroup:1,
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true
			},
			on: {
				init: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				},
				slideChange: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				}
			}
		},


		carrousel_region: {
			direction: "horizontal",
			loop: true,
			breakpoints: {
				0: {
					// slidesPerView: 1.3,
					// spaceBetween: 8,
					// slidesPerGroup:3
				},
				500: {
					// slidesPerView: 2.2,
					// spaceBetween: 8,
					// slidesPerGroup:2
				},
				768: {
					// slidesPerView: 2.4,
					// spaceBetween: 16,
					// slidesPerGroup:2
				},
				992: {
					slidesPerView: 1,
					spaceBetween: 32,
					slidesPerGroup: 1
				},
				1024: {
					// slidesPerView: 2.4,
					// spaceBetween: 32,
					// slidesPerGroup:2
				},
				1280: {
					 slidesPerView: 1.8,
					 spaceBetween: 43,
					 slidesPerGroup: 1,
					 slidesOffsetBefore: 43
				}
			},
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true
			},
			on: {
				init: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				},
				slideChange: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				}
			}
		},


		carrousel_entete_accueil: {
			direction: "horizontal",
			loop: true,
			autoplay: {
			    delay: 4500,
			    disableOnInteraction: false,
			},
			breakpoints: {
				0: {
					slidesPerView: 1,
					spaceBetween: 0,
					slidesPerGroup: 1,
				},
				1280: {
					 slidesPerView: 1,
					 spaceBetween: 0,
					 slidesPerGroup: 1,
				}
			},
			on: {
				init: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				},
				slideChange: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				}
			}
		},


		carrousel_service: {
			direction: "horizontal",
			loop: true,
			breakpoints: {
				0: {
					// slidesPerView: 1.3,
					// spaceBetween: 8,
					// slidesPerGroup:3
				},
				500: {
					// slidesPerView: 2.2,
					// spaceBetween: 8,
					// slidesPerGroup:2
				},
				768: {
					slidesPerView: 1,
					spaceBetween: 32,
					slidesPerGroup: 1,
					slidesOffsetBefore: 0
				},
				992: {
					slidesPerView: 1.5,
					spaceBetween: 40,
					slidesPerGroup: 1,
					slidesOffsetBefore: 30
				},
				1024: {
					slidesPerView: 2.1,
					spaceBetween: 40,
					slidesPerGroup: 1,
					slidesOffsetBefore: 30
				},
				1280: {
					 slidesPerView: 2.8,
					 spaceBetween: 86,
					 slidesPerGroup: 1,
					 slidesOffsetBefore: 86
				}
			},
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true
			},
			on: {
				init: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				},
				slideChange: swiper => {
					//this.app.layout.doMatchHeight(swiper.el);
				}
			}
		},
		/**
		 * Fin Skins
		 */
	};

	// Liste des swipers
	swipers = [];

	/**
	 * Constructor
	 * @param app
	 */
	constructor(app) {
		this.app = app;
		console.log("Henri Swipers constructor");
	}

	/**
	 * Init
	 */
	init() {
		console.log("Henri Swipers init!");

		//On loop dans tous les container swiper-container et on les initialise
		const containers = this.app.mainContainer.querySelectorAll(
			"div.swiper-container"
		);
		let swiperItem;
		containers.forEach((container, index) => {
			swiperItem = this.initSwiperItem(container, index);
			if (swiperItem instanceof Swiper) {
				this.swipers.push({
					el: swiperItem,
					index
				});
			}
		});
		return this;
	}

	/**
	 * go swiper item
	 * @param containerEl
	 * @param index
	 */
	initSwiperItem(containerEl, index) {
		console.log("initSwiper", containerEl, index);

		// Skin
		let skinName = containerEl.dataset.skin;
		if (skinName === undefined || this.skins[skinName] === undefined) {
			skinName = "default";
		}
		containerEl.classList.add(`theme-${skinName}`);
		const skin = this.skins[skinName];

		// .swiper element
		const swiperElCheck = containerEl.querySelector("div.swiper");
		if (swiperElCheck === null) {
			console.error(
				"Swiper Main container is not present! div with the class 'swiper' is required"
			);
			return false;
		}
		const swiperEl = swiperElCheck;

		// Next prev nav (added here, to be external of the main div
		const nextEl = containerEl.querySelector(".swiper-button-next");
		const prevEl = containerEl.querySelector(".swiper-button-prev");
		if (nextEl && prevEl) {
			skin.navigation = {
				nextEl,
				prevEl
			};
		}
		//Page navigation
		//TODO: modifier pour pouvoir paramétrer le type pour chaque swiper, là on overwrite tout.
		const pagEl = containerEl.querySelector(".swiper-pagination");
		if(pagEl){
			skin.pagination = {
				el: pagEl,
				type: "bullets",
				clickable: true
			};
		}

		// GoGo Swiper!
		const swiper = new Swiper(swiperEl, skin);
		return swiper;
	}
}


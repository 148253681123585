/**
 * App class
 * Classe principale pour thème WP Henri.
 *
 * @author Stef Funaro
 *
 */
import { Layout } from "./controllers/Layout";
import { Animation } from "./controllers/Animation";
import { Forms } from "./controllers/Forms";
import { Navigation } from "./controllers/Navigation";
import { Swipers } from "./components/Swipers";
import { SocialShareLinks } from "./components/SocialShareLinks";
import { PostsListing } from "./components/PostsListing";
import { TomSelects } from "./components/TomSelects";

export class App {
	
	_okToGo = false;
	_sliders;
	constructor() {

		console.log("Henri App Constructor");

		document.documentElement.classList.remove("no-js");

		// Main container
		this._mainContainer = document.body;

		// Ui controllers
		this.animation = new Animation(this);
		this.layout = new Layout(this);
		this.forms = new Forms(this);
		this.navigation = new Navigation(this);
		this._okToGo = true;



		

		

	}

	/**
	 * init
	 */
	init() {
		if (!this._okToGo) {
			return;
		}
		console.log("Henri App init!");
		this.initControllers();
		this.initComponents();
		this.initModuleCarrouselContenuNumerote();
		this.initModuleListingOrganismes();
		this.initModuleCarte();
	}

	/**
	 * initControllers
	 */
	initControllers() {
		// Controllers
		this.layout.init();
		this.forms.init();
		this.navigation.init();
		this.animation.init();
	}

	/**
	 * initComponents
	 */
	initComponents() {

		this._sliders = new Swipers(this);
		this._sliders.init();

		// Social Share
		const socialShare = new SocialShareLinks();
		socialShare.init();

		// TomSelects
		const tomSelects = new TomSelects();
		tomSelects.init();

		this._mainContainer
			.querySelectorAll(".henri-posts-listing")
			.forEach((listEl, index) => {
				console.log("List!");
				const postListing = new PostsListing(this, listEl);
			});

		//Lightbox (voir dans le fichier)
		// const lightboxes = new Lightboxes(this);
		// lightboxes.init();
	}


	initModuleCarrouselContenuNumerote(){

		var carouselWrapper = document.querySelector('.carrousel-wrapper');


		if(carouselWrapper){

			var containerSlides = carouselWrapper.querySelector('.slides');
			var slides = containerSlides.querySelectorAll('.slide');
			var containerSlidesWidth = containerSlides.clientWidth;
			var screenWidth = window.innerWidth;
			var rightMostPoint = screenWidth;
			var currentTranslate = 0;
			var totalWidth = 0;

			for(let i=0; i < slides.length; i++){
				totalWidth += slides[i].clientWidth;
			}

			carouselWrapper.querySelector(".carousel-btn-next").addEventListener("click", e => {
				e.preventDefault();

			    if(rightMostPoint < totalWidth){

			    	if(totalWidth - rightMostPoint >= 500){
			    		currentTranslate -= 500;
			    		rightMostPoint += 500;
			    		containerSlides.style.transform = 'translateX(' + currentTranslate + 'px)';

			    	}else{
			    		currentTranslate -= totalWidth - rightMostPoint;
			    		rightMostPoint += totalWidth - rightMostPoint;
			    		containerSlides.style.transform = 'translateX(' + currentTranslate + 'px)';
			    	}
			    }
			});


			carouselWrapper.querySelector(".carousel-btn-prev").addEventListener("click", e => {
				e.preventDefault();
				const button = e.currentTarget;

			    if(rightMostPoint > screenWidth){

			    	if(rightMostPoint - screenWidth >= 500){
			    		currentTranslate += 500;
			    		rightMostPoint -= 500;
			    		containerSlides.style.transform = 'translateX(' + currentTranslate + 'px)';

			    	}else{
			    		currentTranslate += rightMostPoint - screenWidth;
			    		rightMostPoint -= rightMostPoint - screenWidth;
			    		containerSlides.style.transform = 'translateX(' + currentTranslate + 'px)';
			    	}

			    }

			});
			

			
			window.addEventListener('resize', function(event) {
			    screenWidth = window.innerWidth;
				rightMostPoint = screenWidth;
				currentTranslate = 0;
	    		containerSlides.style.transform = 'translateX(' + currentTranslate + 'px)';
			}, true);
		}
		
	}




	initModuleListingOrganismes(){


		var moduleListingOrganismes = document.querySelector('.htb-listing-organisme');

		if(moduleListingOrganismes){

			var searchBtn = document.querySelectorAll(".search-btn");
			var resetSearchStn = document.getElementById("reset-search-btn");
			var selects = document.querySelectorAll(".recherche-filtres-wrapper select");


			for (var i = 0; i < searchBtn.length; i++) {

			  	searchBtn[i].addEventListener("click", e => {
					e.preventDefault();

					var urlParams = "";
					var motsCles = document.getElementById("mots-cles").value;
					var region = document.getElementById("regions").value;
					var mrc = document.getElementById("mrcs").value;
					var service = document.getElementById("services").value;
					var secteur = document.getElementById("secteurs").value;



					if(region){
						if (urlParams === "") {
				            urlParams = "region=" + region;
				        } else {
				            urlParams += "&region=" + region;
				        }
					}

					if(mrc){
						if (urlParams === "") {
				            urlParams = "mrc=" + mrc;
				        } else {
				            urlParams += "&mrc=" + mrc;
				        }
					}

					if(service){
						if (urlParams === "") {
				            urlParams = "service=" + service;
				        } else {
				            urlParams += "&service=" + service;
				        }
					}

					if(secteur){
						if (urlParams === "") {
				            urlParams = "secteur=" + secteur;
				        } else {
				            urlParams += "&secteur=" + secteur;
				        }
					}



					
					if (motsCles) {
					    motsCles = motsCles.split(",");
					    for (var i = 0; i < motsCles.length; i++) {
					        if (urlParams === "") {
					            urlParams = "mots-cles[]=" + encodeURIComponent(motsCles[i].trim());
					        } else {
					            urlParams += "&mots-cles[]=" + encodeURIComponent(motsCles[i].trim());
					        }
					    }
					}

					var url = window.location.origin + window.location.pathname;
					window.location.href = url + "?" + urlParams + "#recherche";

				});
			}

			resetSearchStn.addEventListener("click", e => {
				e.preventDefault();
				this.updateSearchSelectOptions();
				var url = window.location.origin + window.location.pathname;
				window.location.href = url + "#recherche";

			});


			for (var i = 0; i < selects.length; i++) {

				selects[i].addEventListener("change", e => {

					this.updateSearchSelectOptions();

				});
			}

			  	
			
		}
	}



	initModuleCarte(){


		var moduleCarte = document.querySelector('.htb-carte');

		if(moduleCarte){

			var regionsText = moduleCarte.querySelectorAll('.liste-regions .region a');
			var svgCarte = moduleCarte.querySelector('.carte svg');
			var regionsCarte = moduleCarte.querySelectorAll('.carte svg a');
			var mapZoomToggle = moduleCarte.querySelector("#map-zoom-toggle");
			var sectionsToToggle = moduleCarte.querySelectorAll('.carte svg a .hide-zoom-in');
			var zoomedIn = true;

			for (var i = 0; i < regionsText.length; i++) {

			  	regionsText[i].addEventListener("mouseover", e => {
			  		var id = e.target.closest(".region").getAttribute('data-id');
			  		var path = moduleCarte.querySelector('.carte svg a[data-id="' + id + '"]');
			  		path.classList.add("active");
			  	});

			  	regionsText[i].addEventListener("mouseleave", e => {
			  		var id = e.target.closest(".region").getAttribute('data-id');
			  		var path = moduleCarte.querySelector('.carte svg a[data-id="' + id + '"]');
			  		path.classList.remove("active");
			  	});
			}


			for (var i = 0; i < regionsCarte.length; i++) {

			  	regionsCarte[i].addEventListener("mouseover", e => {
			  		var id = e.target.closest("a").getAttribute('data-id');
			  		var link = moduleCarte.querySelector('.liste-regions .region[data-id="' + id + '"]');
			  		link.classList.add("active");
			  	});

			  	regionsCarte[i].addEventListener("mouseleave", e => {
			  		var id = e.target.closest("a").getAttribute('data-id');
			  		var link = moduleCarte.querySelector('.liste-regions .region[data-id="' + id + '"]');
			  		link.classList.remove("active");
			  	});
			}

			/*
			mapZoomToggle.addEventListener("click", e => {
		  		
		  		zoomedIn = !zoomedIn;

		  		if(zoomedIn){

		  			for (var i = 0; i < sectionsToToggle.length; i++) {
		  				sectionsToToggle[i].classList.add("hide");
		  			}

		  			svgCarte.setAttribute("viewBox", "0 390 900 953");
		  			mapZoomToggle.querySelector('.symbol').innerHTML = "+";


		  		}else{

		  			for (var i = 0; i < sectionsToToggle.length; i++) {
		  				sectionsToToggle[i].classList.remove("hide");;
		  			}

		  			svgCarte.setAttribute("viewBox", "0 0 900 953");
		  			mapZoomToggle.querySelector('.symbol').innerHTML = "-";
		  		}

		  	});
			*/
		}
	}



	updateSearchSelectOptions(){

		var region = document.getElementById("regions").value;
		var mrc = document.getElementById("mrcs").value;
		var service = document.getElementById("services").value;
		var secteur = document.getElementById("secteurs").value;

		jQuery.ajax({
			type: "POST",
			url: window.ajaxurl,
			data: {
				action: "get_search_select_options",
				region: region,
			    mrc: mrc,
			    service: service,
			    secteur: secteur,
			},
			success: function (data) {
				data = JSON.parse(data);

				var regionAvailable = data['region_available'];
	            var mrcAvailable = data['mrc_available'];
	            var serviceAvailable = data['service_available'];
	            var secteurAvailable = data['secteur_available'];

	            var regionSelect = document.getElementById("regions");
				var mrcSelect = document.getElementById("mrcs");
				var serviceSelect = document.getElementById("services");
				var secteurSelect = document.getElementById("secteurs");

				//Remove all options but the empty value
				
				for (var j = regionSelect.options.length; j >= 1; j--) {
				  	regionSelect.remove(j);
				}

				for (var j = mrcSelect.options.length; j >= 1; j--) {
				  	mrcSelect.remove(j);
				}

				for (var j = serviceSelect.options.length; j >= 1; j--) {
				  	serviceSelect.remove(j);
				}

				for (var j = secteurSelect.options.length; j >= 1; j--) {
				  	secteurSelect.remove(j);
				}


				//Add the new options
				for(var j = 0; j < regionAvailable.length; j++){
					var option = document.createElement('option');
				    option.value = regionAvailable[j]["term_id"];
				    option.innerText = regionAvailable[j]["name"];

				    if(regionAvailable[j]["term_id"] == region){
						option.selected = true;
				    }

				    regionSelect.appendChild(option);
				}
				

				for(var j = 0; j < mrcAvailable.length; j++){
					var option = document.createElement('option');
				    option.value = mrcAvailable[j]["term_id"];
				    option.innerText = mrcAvailable[j]["name"];

				    if(mrcAvailable[j]["term_id"] == mrc){
						option.selected = true;
				    }

				    mrcSelect.appendChild(option);
				}
				
				for(var j = 0; j < serviceAvailable.length; j++){
					
					var option = document.createElement('option');
				    option.value = serviceAvailable[j]["term_id"];
				    option.innerText = serviceAvailable[j]["name"];

				    if(serviceAvailable[j]["term_id"] == service){
						option.selected = true;
				    }

				    serviceSelect.appendChild(option);
				}

				for(var j = 0; j < secteurAvailable.length; j++){
					var option = document.createElement('option');
				    option.value = secteurAvailable[j]["term_id"];
				    option.innerText = secteurAvailable[j]["name"];

				    if(secteurAvailable[j]["term_id"] == secteur){
						option.selected = true;
				    }

				    secteurSelect.appendChild(option);
				}
			}
		});
	}




	getSliders(){
		return this._sliders;
	}

	/**
	 * main container
	 */
	get mainContainer() {
		return this._mainContainer;
	}

}

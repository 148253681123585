/**
 * TomSelects class
 * Ici on gère les select "tom select"
 *
 * Documentation:
 * https://tom-select.js.org/
 *
 *
 * @author Stef Funaro
 *
 */
import TomSelect from "tom-select";
import { Marquee } from "../animations/Marquee";

export class TomSelects {
	constructor () {
		console.log("TomSelects.constructor");
	}

	init () {
		var settings = {};
		document.querySelectorAll(".tom-select").forEach((listEl, index) => {
			try {
				new TomSelect(listEl,settings);
			}
			catch (e) { // <-- note `e` has explicit `unknown` type
				e.message; // errors
				console.log("Erreur TomSelect=",e);
			}
		});
	}
}

/**
 *
 * Listing de posts (hpl)
 * Classe: henri-posts-listing
 *
 * Container
 * Classe: hpl-content
 * LAISSER VIDE SI VOUS VOULEZ UN LOAD INITIAL PAR JS, OU PRÉPOPULER PAR PHP (Respectez le même pagesize que le JS par-contre).
 *
 * Options Container
 * data-pagesize="4" - default=10
 * data-posttype="logement" - default=post
 * data-itemfile="_vc_modules/_dynamic/liste_unites/view/unites_item.php" default=_templates/_partials/post_item_view.php
 * data-orderby="menu_order" default=date
 * data-order="DESC" default=DESC
 *
 *
 * Éléments Optionnels:
 * Bouton "load more": Mettre classe hpl-loadmore-btn
 * Indicateur qu'il n'y a pas se résultat: hpl-noresults
 * Indicateur de loading: hpl-loading
 * Bouton page suivante: Mettre classe hpl-next-page-btn
 * Bouton page précédente: Mettre classe hpl-previous-page-btn
 * Select pour le nombre d'item par pages (la propriété value doit retourner un chiffre): Mettre classe hpl-page-size-select
 * Navigation de page par numéros: Mettre un div VIDE avec la classe hpl-page-numbers-nav //TODO: Fonction non terminée
 *
 *
 * Filtres:
 * Nav (pour un menu d'items cliquables de filtres):
 * class="hpl-filter-menu"
 * data-filtertype="taxonomy" - Obligatoire: taxonomy ou meta ou date
 * data-filterby="logement-type" - Le nom de la taxonomy ou du meta ou format de la date
 * data-filtercumulative="false" - Default false - Si mit à true, les éléments pourront être plusieurs à être sélectionnés en même temps.
 * //TODO: Ajouter possibilité de range de date
 *
 *        Sous-Item de filtre (si ils sont cliquables, pas requis si le filtre est un select, dans le cas d'un select on utilise l'événement 'change' et la 'value':
 *        class="hpl-filter-menu-item"
 *        data-value="value"
 *        Mettre "all" pour que le filtre soit ignoré
 *
 * Search
 * form class="hpl-search-form"
 * input class="hpl-search-input"
 * Le refresh se fera on submit du form
 *
 *
 */
export class PostsListing {
	_searchInput = null;
	_postType = "post";
	_orderBy = "date";
	_order = "DESC";
	_curPage = 0;
	_pageSize = 10;
	_totalPostAvailable = 0;
	_ajaxFunction = "load_posts_listing";
	_viewFile = "_templates/_partials/post_item_view.php";

	/**
	 * Constructor
	 * @param app
	 */
	constructor(app, container) {
		this.app = app;
		this.container = container;
		console.log("Henri PostsListing constructor");

		//Listing Element
		this._contentEl = this.container.querySelector(".hpl-content");
		if (this._contentEl == null) {
			console.error("PostsListing has no .hpl-content container.");
			return;
		}

		//Page
		if (this.container.dataset.curpage !== undefined) {
			this._curPage = Number(this.container.dataset.curpage);
		}
		if (this.container.dataset.pagesize !== undefined) {
			this._pageSize = Number(this.container.dataset.pagesize);
		}
		//Fonction
		if (this.container.dataset.ajaxfunction !== undefined) {
			this._ajaxFunction = this.container.dataset.ajaxfunction;
		}

		//View file
		if (this.container.dataset.itemfile !== undefined) {
			this._viewFile = this.container.dataset.itemfile;
		}

		//Post type
		if (this.container.dataset.posttype !== undefined) {
			this._postType = this.container.dataset.posttype;
		}

		//Order
		if (this.container.dataset.orderby !== undefined) {
			this._orderBy = this.container.dataset.orderby;
		}

		if (this.container.dataset.order !== undefined) {
			this._order = this.container.dataset.order;
		}

		//Load more btn
		this._loadMoreBtn = this.container.querySelector(".hpl-loadmore-btn");
		if (this._loadMoreBtn !== null) {
			this._loadMoreBtn.addEventListener("click", e => {
				e.preventDefault();
				const target = e.currentTarget;
				this._loadMore();
			});
		}

		//Pages nav - next
		this._nextPageBtn = this.container.querySelector(".hpl-next-page-btn");
		if (this._nextPageBtn !== null) {
			this._nextPageBtn.addEventListener("click", e => {
				e.preventDefault();
				const target = e.currentTarget;
				this._nextPage();
			});
		}

		//Pages nav - previous
		this._previousPageBtn = this.container.querySelector(
			".hpl-previous-page-btn"
		);
		if (this._previousPageBtn !== null) {
			this._previousPageBtn.addEventListener("click", e => {
				e.preventDefault();
				const target = e.currentTarget;
				this._previousPage();
			});
		}

		//Search
		this._searchForm = this.container.querySelector("form.hpl-search-form");
		if (this._searchForm !== null) {
			this._searchInput = this._searchForm.querySelector(".hpl-search-input");
			if (this._searchInput !== null) {
				this._searchForm.addEventListener("submit", e => {
					e.preventDefault();
					//const target = e.currentTarget;
					this._refreshData(true, true);
				});
			}
		}

		//Pages navigation
		this._pageNumbersNavigation = this.container.querySelector(
			".hpl-page-numbers-nav"
		);
		if (this._pageNumbersNavigation !== null) {
			this._updatePageNavigation();
		}

		//Pages size select
		this._pageSizeSelect = this.container.querySelector(".hpl-page-size-select");
		if (this._pageSizeSelect !== null) {
			this._pageSizeSelect.addEventListener("change", e => {
				console.log("Select change!!");
				e.preventDefault();
				const target = e.currentTarget;
				if (target.value !== null) {
					this._pageSize = target.value;
				}
				this._refreshData(true, true);
			});
			this._pageSize = this._pageSizeSelect.value;
		}

		//No result
		this._noresultEl = this.container.querySelector(".hpl-noresults");

		//Loading
		this._loadingEl = this.container.querySelector(".hpl-loading");

		//Filters
		document.querySelectorAll(".hpl-filter-menu").forEach((filterMenuEl, index) => {
			console.log("**filter", filterMenuEl);
			console.log("filterby", filterMenuEl.dataset.filterby);
			console.log("filtertype", filterMenuEl.dataset.filtertype);
			console.log("filtercumulative", filterMenuEl.dataset.filtercumulative);
			if (filterMenuEl.tagName === "SELECT") {
				filterMenuEl.addEventListener("change", e => {
					e.preventDefault();
					const target = e.currentTarget;
					console.log("Filter element change", target.value);
					this._refreshData(true, true);
				});
			} else {
				filterMenuEl
					.querySelectorAll(".hpl-filter-menu-item")
					.forEach((filterMenuItemEl, indexItem) => {
						console.log("*filterMenuItemEl", filterMenuItemEl);
						//TODO: gérer checkbox
						filterMenuItemEl.addEventListener("click", e => {
							e.preventDefault();
							const target = e.currentTarget;
							if (target.classList.contains("selected")) {
								target.classList.remove("selected");
							} else {
								if (filterMenuEl.dataset.filtercumulative !== "true") {
									filterMenuEl
										.querySelectorAll(".selected")
										.forEach(i => i.classList.remove("selected"));
								}
								target.classList.add("selected");
							}
							this._refreshData(true, true);
						});
					});
			}
		});

		// console.log("INIT this.container.children.length",this._contentEl.children.length);
		if (this._contentEl.children.length > 0) {
			//TODO: Checker si le nombre correspond à la page et peut-être permettre que le nombre de d'item de départ diffère du pagesize. Genre un offset ?
			this._curPage = 1;
		} else {
			//La liste est vide, on load par JS la première page
			this._refreshData();
		}
	}

	_loadMore() {
		console.log("Load more posts!");
		this._curPage++;
		this._refreshData(false);
	}

	_nextPage() {
		console.log("Next page!");
		this._curPage++;
		this._refreshData(true, false);
	}

	_previousPage() {
		console.log("Previous page!");
		if (this._curPage > 0) {
			this._curPage--;
			this._refreshData(true, false);
		}
	}

	/**
	 * Go to page number
	 * @param $pPageNumber: number (débutant pas zéro)
	 */
	_gotoPage($pPageNumber) {
		this._curPage = $pPageNumber;
		this._refreshData(true, false);
	}

	_refreshData(clear = false, resetPage = false) {
		console.log("_refreshData");
		if (clear) {
			this._contentEl.innerHTML = "";
		}
		if (resetPage) {
			this._curPage = 0;
		}
		this._hideLoadMore();
		this._hideNoResult();
		this._showLoading();
		//Construction d'un array avec les données de filtres, basé sur ce qui est selected dans les menus de filtres.
		const filtersData = {taxonomy: {}, meta: {}, date: {}};
		//Loop chaque menu
		document
			.querySelectorAll(".hpl-filter-menu")
			.forEach((filterMenuEl, index) => {
				// console.log("**filter", filterMenuEl);
				// console.log("filterby", filterMenuEl.dataset.filterby);
				// console.log("filtertype", filterMenuEl.dataset.filtertype);
				// console.log("filtercumulative", filterMenuEl.dataset.filtercumulative);
				const menuFilters = [];
				//Chaque item sélectionné
				//TODO: gérer checkbox (:checked et value)
				if (filterMenuEl.tagName === "SELECT") {
					const selectEl = filterMenuEl;
					if (
						selectEl.value !== undefined &&
						selectEl.value !== "all" &&
						selectEl.value !== "tous"
					) {
						console.log("*filterMenuItemEl Select value", selectEl.value);
						menuFilters.push(selectEl.value);
					}
				} else {
					filterMenuEl
						.querySelectorAll(".hpl-filter-menu-item.selected")
						.forEach((filterMenuItemEl, indexItem) => {
							console.log("*filterMenuItemEl", filterMenuItemEl);
							if (
								filterMenuItemEl.dataset.value !== undefined &&
								filterMenuItemEl.dataset.value !== "all" &&
								filterMenuItemEl.dataset.value !== "tous"
							) {
								menuFilters.push(filterMenuItemEl.dataset.value);
							}
						});
				}
				//Si on a trouvé de quoi, on l'ajoute à l'array de filtres
				if (menuFilters.length > 0) {
					if (
						filterMenuEl.dataset.filterby !== undefined &&
						filterMenuEl.dataset.filtertype !== undefined
					) {
						console.log(
							"Add filter=",
							filterMenuEl.dataset.filtertype,
							filterMenuEl.dataset.filterby,
							menuFilters
						);
						filtersData[filterMenuEl.dataset.filtertype][
							filterMenuEl.dataset.filterby
						] = menuFilters;
					}
				}
			});

		//Search
		let searchString = "";
		if (this._searchInput !== null) {
			searchString = this._searchInput.value;
		}

		// console.log("_refreshData", filtersData);
		// console.log("_refreshData string", JSON.stringify(filtersData));

		const sendData = {
			action: this._ajaxFunction,
			type: this._postType,
			filters: filtersData,
			page: this._curPage,
			pagesize: this._pageSize,
			post_type: this._postType,
			view_file: this._viewFile,
			orderby: this._orderBy,
			order: this._order,
			search: searchString
		};

		jQuery.ajax({
			url: window.ajaxurl,
			method: "post",
			data: sendData,
			dataType: "json",
			success: response => {
				// console.log("response=", response);
				this._displayResponse(response);
			}
		});

		return;

		//TODO: convertir le call ajax en vanille (ben de la misère à passer un Array)
		//
		// const data = new FormData();
		// data.append("action", this._ajaxFunction);
		// data.append("post_type", this._postType);
		// data.append("taxonomy", JSON.stringify(filtersData["taxonomy"]));
		// data.append("page", this._curPage.toString());
		// data.append("pagesize", JSON.stringify(this._pageSize));
		//
		// fetch((window as any).ajaxurl, {
		// 	method: "POST",
		// 	headers: {
		// 	},
		// 	body: data,
		// })
		// 	.then((response) => response.json())
		// 	.then((data) => {
		// 		console.log("Success:", data);
		// 	})
		// 	.catch((error) => {
		// 		console.error("Error:", error);
		// 	});
	}

	_displayResponse(response) {
		this._hideLoading();
		const lastPostAvailable = this._totalPostAvailable;
		this._totalPostAvailable = response.count;
		if (response.items.length > 0) {
			response.items.forEach((item, index) => {
				this._contentEl.innerHTML = this._contentEl.innerHTML + item;
			});
		} else {
			//Reçu du vide ?
			if (this._contentEl.children.length > 0) {
				//S'il y avait déjà du stock, c'est qu'on est rendu au boutte
				this._hideNoResult();
			} else {
				//On avait rien et on reçoit rien. Donc no result.
				this._showNoResult();
			}
		}
		//On a moins d'éléments d'affichés que ce qui est trouvé par les filtres
		if (this._contentEl.children.length < response.count) {
			//TODO: y'a un bug ici, on semble avoir un item de moins, ça laisse une page vide
			this._showLoadMore();
			this._showNextPageBtn();
		} else {
			this._hideNextPageBtn();
		}
		if (this._curPage == 0) {
			this._hidePreviousPageBtn();
		} else {
			this._showPreviousPageBtn();
		}
		//On rebuild la navigation à chaque fois que le nombre total change
		if (lastPostAvailable !== this._totalPostAvailable) {
			this._updatePageNavigation();
		}
		console.log("_displayResponse items", response.items);
	}

	/**
	 * Update page navigation
	 */
	_updatePageNavigation() {
		if (this._pageNumbersNavigation !== null) {
			this._contentEl.innerHTML = "<ul>";

			//TODO: Ici looper et ajouter des li, leur mettre un click qui callera _gotoPage
			//Utiliser la variable this._totalPostAvailable et this._pageSize
			//TODO: s'inspirer de FTQ pour afficher les chiffres avec des ... quand il y a trop de pages.

			this._contentEl.innerHTML += "</ul>";
		}
	}

	_showLoading() {
		if (this._loadingEl !== null) {
			this._loadingEl.classList.remove("hide");
		}
	}

	_hideLoading() {
		if (this._loadingEl !== null) {
			this._loadingEl.classList.add("hide");
		}
	}

	_showNoResult() {
		if (this._noresultEl !== null) {
			this._noresultEl.classList.remove("hide");
		}
	}

	_hideNoResult() {
		if (this._noresultEl !== null) {
			this._noresultEl.classList.add("hide");
		}
	}

	_hideLoadMore() {
		if (this._loadMoreBtn !== null) {
			this._loadMoreBtn.classList.add("hide");
		}
	}

	_showLoadMore() {
		if (this._loadMoreBtn !== null) {
			this._loadMoreBtn.classList.remove("hide");
		}
	}

	_hidePreviousPageBtn() {
		if (this._previousPageBtn !== null) {
			this._previousPageBtn.classList.add("hide");
		}
	}

	_showPreviousPageBtn() {
		if (this._previousPageBtn !== null) {
			this._previousPageBtn.classList.remove("hide");
		}
	}

	_hideNextPageBtn() {
		if (this._nextPageBtn !== null) {
			this._nextPageBtn.classList.add("hide");
		}
	}

	_showNextPageBtn() {
		if (this._nextPageBtn !== null) {
			this._nextPageBtn.classList.remove("hide");
		}
	}
}
